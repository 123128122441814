import { createStore }      from 'redux';
import { persistReducer }   from 'redux-persist';
import   storageSession     from 'redux-persist/lib/storage/session';

const persistConfig = {
  key: 'root',
  storage: storageSession
};

const initialState = {
  currentUserType: '',
  companyAccountStatus: '',
  companyPermissions: [],
  companyCsrfToken: '',
  adminPermissions: [],
  adminCsrfToken: '',
  currentPromoText: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'COMPANY_LOGIN':
      return { ...initialState,
               currentUserType: 'company',
               companyAccountStatus: action.companyAccountStatus,
               companyPermissions: action.companyPermissions,
               companyCsrfToken: action.companyCsrfToken };
    case 'ADMIN_LOGIN':
      return { ...initialState,
               currentUserType: 'admin',
               adminPermissions: action.adminPermissions,
               adminCsrfToken: action.adminCsrfToken };
    case 'LOGOUT':
      return initialState;
    case 'UPDATE_CURRENT_PROMOTEXT':
      return { ...state, currentPromoText: action.currentPromoText };
    default:
      return state;
  }
};

const persistedReducer = persistReducer(persistConfig, reducer);
const store = createStore(persistedReducer);

export default store;
