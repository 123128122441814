import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import './Navigation.css';
import Logo from '../assets/logo.svg';
import { connect } from 'react-redux';
import Navbar from 'react-bootstrap/Navbar'

class Navigation extends PureComponent {
  render() {
    return (
        <div>
          <Navbar expand={'md'} variant={'dark'} className="purpleBg">
            <Navbar.Brand href="/"><img src={Logo} width="63.57" height="22" alt='logo'/></Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarSupportedContent"/>
            <Navbar.Collapse className="ml-auto flex-grow-0" id="navbarSupportedContent">
            { this.props.currentUserType === 'admin' ?
              <ul className="navbar-nav">
                <li><NavLink to='/admin/logout' activeStyle={activeStyle}>Logout</NavLink></li>
              </ul> :
              this.props.currentUserType === 'company' ?
              <ul className="navbar-nav">
                <li><NavLink to='/locations' activeStyle={activeStyle}>My Locations</NavLink></li>
                <li><NavLink to='/accountsettings' activeStyle={activeStyle}>My Settings</NavLink></li>
                <li><NavLink to='/contact' activeStyle={activeStyle}>Contact</NavLink></li>
                <li><NavLink to='/logout' activeStyle={activeStyle}>Logout</NavLink></li>
              </ul> :
              <ul className="navbar-nav">
                <li><NavLink to='/login' activeStyle={activeStyle}>For Restaurants</NavLink></li>
              </ul>
            }
            </Navbar.Collapse>
          </Navbar>
        </div>
    );
  }
}

const activeStyle = {
  fontWeight: '700'
};

function mapStateToProps({ currentUserType }) {
  return { currentUserType };
}

export default connect(mapStateToProps)(Navigation);
