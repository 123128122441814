import React from 'react';
import './Oops.css';

class Oops extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) return <div>Oops, something went wrong. Please try refreshing the page.</div>;
    return this.props.children;
  }
}

export default Oops;
