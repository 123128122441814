import   React,
       { Component }        from 'react';
import { Provider }         from 'react-redux';
import   AppStack           from './AppStack';
import   AppStore           from './AppStore';
import { persistStore }     from 'redux-persist';
import { PersistGate }      from 'redux-persist/integration/react';

const persistor = persistStore(AppStore);

class App extends Component {
  render() {
    return (
        <Provider store={AppStore}>
          <PersistGate loading={null} persistor={persistor}>
            <AppStack />
          </PersistGate>
        </Provider>
    );
  }
}

export default App;
