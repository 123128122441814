import React, {Component} from 'react';
import './Home.css';
import Footer from './Footer';

class Home extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div>



        <div className="Home-restaurantLoginFrame peachBg">
          <h1 className="Home-h1">Ahi Means Business</h1>
          <p>Test Text</p>
          <button className="buttonLg purpleBg Home-footerMargin" onClick={() => {this.props.history.push('/login');}}>Restaurateur Log In</button>
          <Footer />
        </div>
      </div>
    );
  };
}

export default Home;
