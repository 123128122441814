import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Home from './components/open/Home';
import Oops from './components/open/Oops';
import Navigation from './components/open/Navigation';
const RestaurantCollection = lazy(() => import('./components/company/RestaurantCollection'));
const ItemCollection = lazy(() => import('./components/company/ItemCollection'));
const ItemEdit = lazy(() => import('./components/company/ItemEdit'));
const Giveaways = lazy(() => import('./components/company/Giveaways'));
const Promos = lazy(() => import('./components/company/Promos'));
const RestaurantEdit = lazy(() => import('./components/company/RestaurantEdit'));
const Login = lazy(() => import('./components/open/Login'));
const ForgotPassword = lazy(() => import('./components/open/ForgotPassword'));
const ResetPassword = lazy(() => import('./components/open/ResetPassword'));
const Signup = lazy(() => import('./components/open/Signup'));
const VerifyAccount = lazy(() => import('./components/open/VerifyAccount'));
const VerifyEmail = lazy(() => import('./components/open/VerifyEmail'));
const AccountSettings = lazy(() => import('./components/company/AccountSettings'));
const EditCompanyDetails = lazy(() => import('./components/company/EditCompanyDetails'));
const EditCompanyEmail = lazy(() => import('./components/company/EditCompanyEmail'));
const EditCompanyPassword = lazy(() => import('./components/company/EditCompanyPassword'));
const DeleteCompany = lazy(() => import('./components/company/DeleteCompany'));
const Logout = lazy(() => import('./components/company/Logout'));
const Contact = lazy(() => import('./components/open/Contact'));
const Error = lazy(() => import('./components/open/Error'));
const LegalMenu = lazy(() => import('./components/legal/LegalMenu'));
const UserAgreement = lazy(() => import('./components/legal/UserAgreement'));
const RestaurantServiceAgreement = lazy(() => import('./components/legal/RestaurantServiceAgreement'));
const Licenses = lazy(() => import('./components/legal/Licenses'));
const AdminLogin = lazy(() => import('./components/open/AdminLogin'));
const AdminMenu = lazy(() => import('./components/admin/AdminMenu'));
const AdminLogout = lazy(() => import('./components/admin/AdminLogout'));
const AdminCompanyVerification = lazy(() => import('./components/admin/AdminCompanyVerification'));
const AdminCompanyPending = lazy(() => import('./components/admin/AdminCompanyPending'));
const AdminRestaurantSearch = lazy(() => import('./components/admin/AdminRestaurantSearch'));
const AdminRestaurantView = lazy(() => import('./components/admin/AdminRestaurantView'));
const AdminCompanySearch = lazy(() => import('./components/admin/AdminCompanySearch'));
const AdminUserKpi = lazy(() => import('./components/admin/AdminUserKpi'));
const AdminUsageKpi = lazy(() => import('./components/admin/AdminUsageKpi'));

class AppStack extends Component {
  render() {
    const CompanyRoute = ({ component: DisplayComponent, ...rest }) => (
      <Route {...rest} render={props =>
        this.props.currentUserType === 'company' ? <DisplayComponent {...props} /> : <Redirect to={{pathname: '/login'}}/> }
      />
    );
    const AdminRoute = ({ component: DisplayComponent, ...rest }) => (
      <Route {...rest} render={props =>
        this.props.currentUserType === 'admin' ? <DisplayComponent {...props} /> : <Redirect to={{pathname: '/admin'}}/> }
      />
    );
    return (
      <BrowserRouter>
        <Oops>
          <Suspense fallback={ <div>Loading...</div> }>
            <Navigation />
            <Switch>
              <Route path='/' component={Home} exact />
              <Route path='/contact' component={Contact} />
              <Route path='/login' component={Login} />
              <Route path='/forgotpassword' component={ForgotPassword} />
              <Route path='/resetpassword/:passwordResetToken' component={ResetPassword} />
              <Route path='/signup' component={Signup} />
              <Route path='/verifyaccount/:signupVerificationToken' component={VerifyAccount} />
              <Route path='/verifyemail/:emailUpdateToken' component={VerifyEmail} />
              <Route path='/legal' component={LegalMenu} exact />
              <Route path='/legal/user' component={UserAgreement} exact />
              <Route path='/legal/restaurant' component={RestaurantServiceAgreement} exact />
              <Route path='/legal/licenses' component={Licenses} exact />
              <CompanyRoute path='/locations' component={RestaurantCollection} exact />
              <CompanyRoute path='/locations/restaurant' component={RestaurantEdit} exact />
              <CompanyRoute path='/locations/restaurant/:restaurantId' component={RestaurantEdit} exact />
              <CompanyRoute path='/locations/items/:restaurantId' component={ItemCollection} exact />
              <CompanyRoute path='/locations/item/:restaurantId' component={ItemEdit} exact />
              <CompanyRoute path='/locations/item/:restaurantId/:itemId' component={ItemEdit} exact />
              <CompanyRoute path='/locations/tickets/:restaurantId' component={Giveaways} exact />
              <CompanyRoute path='/locations/promos/:restaurantId' component={Promos} exact />
              <CompanyRoute path='/accountsettings' component={AccountSettings} exact />
              <CompanyRoute path='/accountsettings/companydetails' component={EditCompanyDetails} exact />
              <CompanyRoute path='/accountsettings/companyemail' component={EditCompanyEmail} exact />
              <CompanyRoute path='/accountsettings/companypassword' component={EditCompanyPassword} exact />
              <CompanyRoute path='/accountsettings/companydelete' component={DeleteCompany} exact />
              <CompanyRoute path='/logout' component={Logout} />
              <Route path='/admin' component={AdminLogin} exact />
              <AdminRoute path='/admin/menu' component={AdminMenu} exact />
              <AdminRoute path='/admin/logout' component={AdminLogout} exact />
              <AdminRoute path='/admin/companyverification' component={AdminCompanyVerification} exact />
              <AdminRoute path='/admin/companypending' component={AdminCompanyPending} exact />
              <AdminRoute path='/admin/restaurantsearch' component={AdminRestaurantSearch} exact />
              <AdminRoute path='/admin/restaurantview/:restaurantId' component={AdminRestaurantView} exact />
              <AdminRoute path='/admin/companysearch' component={AdminCompanySearch} exact />
              <AdminRoute path='/admin/kpi/user' component={AdminUserKpi} exact />
              <AdminRoute path='/admin/kpi/usage' component={AdminUsageKpi} exact />
              <Route component={Error} />
            </Switch>
          </Suspense>
        </Oops>
      </BrowserRouter>
    );
  }
}

function mapStateToProps({ currentUserType }) {
  return { currentUserType };
}

export default connect(mapStateToProps)(AppStack);
