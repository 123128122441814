import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import './Footer.css';

class Footer extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
  return (
    <footer className='Footer-frame'>
      <div>

        <NavLink to='/legal' activeStyle={activeStyle}>Legal</NavLink>
        <span className="Footer-text">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <NavLink to='/contact' activeStyle={activeStyle}>Contact</NavLink>
      </div>
      <p className="Footer-copyright">&copy; 2018</p>
    </footer>
  );
  }
}

const activeStyle = {
  fontWeight: '700'
};

export default Footer;
